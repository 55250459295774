import {
    ref,
    computed,
    watch,
    type MaybeRefOrGetter,
    type Ref,
    toValue,
    getCurrentInstance,
    type ComputedRef,
    provide,
    inject
} from 'vue'
import type { CustomerAddressModel } from '../models/customer-address.model'
import { ApiResponse } from '@composable-api/api.response'
import type { OrderAddressModel } from '../models/order-address.model'
import type { ProductVariationModel } from '../models/product-variation.model'
import type { ProductVariationPropertyModel } from '../models/custom/product-variation-property.model'
import type { ProductVariationPropertyAttributeModel } from '../models/custom/product-variation-property-attribute.model'
import type { ProductModel } from '../models/product.model'
import defu from 'defu'
import { useRoute, useRouter } from '#app'
import { withQuery } from 'ufo'

/**
 * Transforms the Customer Address models into an object, where the
 * default shipping & billing addresses are separated.
 * @param addresses The addresses to transform or an ApiResponse with the addresses (must be a ref to be reactive)
 */
export function useTransformedCustomerAddresses<T extends CustomerAddressModel | OrderAddressModel>(addresses: MaybeRefOrGetter<T[] | ApiResponse<T> | undefined>) {
    return computed(() => {
        const out: {
            billing: T | null
            shipping: T | null
            all: T[]
        } = {
            billing: null,
            shipping: null,
            all: [],
        }

        let _addresses = toValue(addresses)
        if (_addresses instanceof ApiResponse) {
            _addresses = _addresses.getItems()
        }

        if (!_addresses || !_addresses.length) return out

        for (const address of _addresses) {
            if (('isBillingDefault' in address && address.isBillingDefault) || ('isBilling' in address && address.isBilling())) {
                out.billing = address
            }

            if (('isShippingDefault' in address && address.isShippingDefault) || ('isShipping' in address && address.isShipping())) {
                out.shipping = address
            }

            out.all.push(address)
        }
        return out
    })
}

interface UseProductVariationsOptions {
    /**
     * Whether to allow to select variations that are unavailable for purchase.
     * (equal to the `canBePurchased()` method of the ProductVariationModel)
     *
     * When set to `true`, all variations passed to this composable will be considered.
     *
     * @default false
     */
    allowNotPurchasable: boolean
    /**
     * The strategy to use when selecting the variations.
     * - `path`: The order in which the variations were selected is stored
     *          & changing previous selections will reset the following ones
     * - `combination`: The selected variations are stored only as a combination & changing
     *                 other properties might not reset the selected ones (WIP, do not use yet)
     *
     * @default 'path'
     */
    strategy: 'path' | 'combination'
    /**
     * Whether to only show the following property to be selected
     * along with the already selected ones.
     *
     * When set to `false`, all properties will be show & the user
     * can start from any property.
     *
     * When set to `true`, the user's selection will be guided from
     * the top to the bottom (first to last property).
     *
     * @default false
     */
    gradualSelection: boolean
    /**
     * Whether to store the selected variation in a query parameter.
     *
     * When enabled, the id of the selected variation will be stored in the query parameter
     * and the selectors will be automatically set when the page is loaded
     * and the variation id is correct.
     *
     * In case an invalid id is provided, no selection will be made.
     *
     * @example will select the variation with id `42`
     * /product?v=42
     *
     * @default true
     */
    storeSelectedVariationInQuery: boolean
}

type SelectedVariation = InstanceType<typeof ProductVariationModel> | null

export type VariationSelectorPropertyChangeData = {
    property: InstanceType<typeof ProductVariationPropertyModel>,
    value: InstanceType<typeof ProductVariationPropertyAttributeModel> | null
}

const VariationSelectorProvideSymbol = Symbol('VariationSelector')

type UseProductVariationSelectorParams = [
    MaybeRefOrGetter<InstanceType<typeof ProductVariationModel>[] | undefined>,
    MaybeRefOrGetter<InstanceType<typeof ProductModel> | null>,
    Partial<UseProductVariationsOptions>?,
] | []

interface UseProductVariationSelectorReturnValue {
    variationProperties: ComputedRef<InstanceType<typeof ProductVariationPropertyModel>[]>
    selectedVariation: Ref<SelectedVariation>
    selectedAttributes: Ref<Record<number, InstanceType<typeof ProductVariationPropertyAttributeModel> | null>>
    getAvailableVariations: (property: InstanceType<typeof ProductVariationPropertyModel>) => InstanceType<typeof ProductVariationModel>[]
    handlePropertyChange: (data: VariationSelectorPropertyChangeData) => void
    finalVariations: ComputedRef<InstanceType<typeof ProductVariationModel>[]>
    showFinalVariationSelector: ComputedRef<boolean>
}

/**
 * A composable to handle the selection of product variations.
 *
 * It provides a reactive way to handle the selection. The following items are exposed:
 * - `variationProperties`: A computed property with all the product variation properties to be rendered. Use this
 *                       to render the individual variation property selector components because it respects
 *                       the selected selection stepping (gradual or all at once). (IMPORTANT)
 * - `selectedVariation`: The selected variation that exactly matches the currently selected attributes. Use this ref
 *                       to get the selected variation to display the product price, image, etc. (IMPORTANT)
 * - `selectedAttributes`: An object with all currently selected variation property attributes as the values & the
 *                        variation property ids as the keys, to be used for `v-model`.
 *                        The variation property selector component should use this object to bind the selected attributes.
 * - `getAvailableVariations()`: A function to get all the available variations for the given product variation property.
 * - `handlePropertyChange()`: A function to handle the change of a product variation property. Should be called every
 *                          time a property changes its value (a variation attribute binding to `selectedAttributes`
 *                          changes its value).
 * - `finalVariations`: The variations that exactly match the currently selected attributes. If there is only one,
 *                   it is automatically set as the selected variation. If there are multiple, the user should
 *                   be prompted to select the final variation.
 * - `showFinalVariationSelector`: A boolean computed property to determine whether to show the extra variation
 *                             selector to let the user pick the final variation when there are multiple possible choices
 *                             and all the attributes are selected. Only use this property to determine whether to show
 *                             the final variation selector or not. (IMPORTANT)
 *
 * ## How it works
 * There are many things to keep in mind when using this composable. It is designed to be able to work in
 * a parent-child context, where it is possible to call the full version of the composable in the parent
 * (provide the variations & product & maybe options) and then call the child version (without parameters)
 * in the child component to access the refs for binding, rendering, etc.
 *
 * This is necessary, for example, because the selected variation needs to be set in the parent when using
 * query parameters to store the selection, because hydration mismatches would occur otherwise.
 *
 * Use the resources provided by this composable for `v-model`& rendering.
 *
 * @example
 * // template
 * <ProductVariationSelector
 *     v-for="property in variationProperties"
 *     :key="property.id!"
 *     v-model="selectedAttributes[property.id!]"
 *     :available-variations="getAvailableVariations(property)"
 *     :property="property"
 *     \@change="handlePropertyChange"
 * />
 *
 * @example Child component (ProductVariationSelector)
 * // script (needs to be called in the correct context - in
 * // a descendant component of a component, which calls the
 * // full version of the `useProductVariationSelector()` composable)
 *
 * const {
 *     variationProperties,
 *     selectedVariation,
 *     finalVariations,
 *     selectedAttributes,
 *     handlePropertyChange,
 *     getAvailableVariations,
 *     showFinalVariationSelector,
 * } = useProductVariationSelector()
 *
 * @example Parent component, which provides the necessary context so that the child component can call it without parameters
 * const { selectedVariation } = useProductVariationSelector(
 *     () => variationsResponse.value?.getItems() ?? [],
 *     () => productResponse.value?.getItem() ?? null
 * )
 */
export function useProductVariationSelector(): UseProductVariationSelectorReturnValue
export function useProductVariationSelector(
    variations: MaybeRefOrGetter<InstanceType<typeof ProductVariationModel>[] | undefined>,
    product: MaybeRefOrGetter<InstanceType<typeof ProductModel> | null>,
    options?: Partial<UseProductVariationsOptions>
): UseProductVariationSelectorReturnValue
export function useProductVariationSelector(...args: UseProductVariationSelectorParams): UseProductVariationSelectorReturnValue {
    if (!getCurrentInstance()) {
        throw new Error('The `useProductVariationSelector()` composable must be called at the top level of ' +
        'the script setup block of a component.')
    }

    // Return the injected value when no arguments are provided
    if (args.length === 0) {
        const injected = inject<UseProductVariationSelectorReturnValue>(VariationSelectorProvideSymbol)
        if (!injected) {
            throw new Error('The `useProductVariationSelector()` composable must be called with the required arguments ' +
            'when not used in the necessary provided context.')
        }

        return injected
    }

    // ----------------------------------------

    const [variations, product, options] = args

    const _options: UseProductVariationsOptions = defu(options, {
        allowNotPurchasable: false,
        strategy: 'path',
        gradualSelection: false,
        storeSelectedVariationInQuery: true,
    } satisfies UseProductVariationsOptions)

    const { variationQuery, updateQueryParam } = useProductVariationQuery({ enabled: _options.storeSelectedVariationInQuery })

    /**
     * This stack represents the current combination of selected variation properties & their attributes.
     * It is also used to determine the available variations for the next property.
     *
     * The individual items represent the selected properties & their attributes in the order they were selected.
     * The last item in the array is the current layer.
     */
    const combinationStack: Ref<{
        /**
         * The product variation property that is selected in the current layer.
         */
        property: InstanceType<typeof ProductVariationPropertyModel>,
        /**
         * The selected attribute of the variation property in the current layer.
         */
        attribute: InstanceType<typeof ProductVariationPropertyAttributeModel>,
        /**
         * The remaining available product variations for the combination of
         * all selected attributes up to the current layer (including).
         *
         * This list should be used for all following layers.
         */
        variations: InstanceType<typeof ProductVariationModel>[]
    }[]> | undefined = _options.strategy === 'path' ? ref([]) : undefined


    const allPossibleProductVariationProperties = computed(() => toValue(product)?.getVariationProperties() ?? [])
    const productVariationPropertiesToRender = computed(() => _options.gradualSelection
        ? allPossibleProductVariationProperties.value.slice(0, (combinationStack?.value.length ?? 0) + 1)
        : allPossibleProductVariationProperties.value
    )

    /**
     * An array with all currently selected variation property attributes used for `v-model`
     * to the individual variation property selector components. (radio groups etc.)
     */
    const selectedAttributes: Ref<Record<number, InstanceType<typeof ProductVariationPropertyAttributeModel> | null>> = ref({})

    const selectedVariation: Ref<SelectedVariation> = ref(null)

    const variationsWithoutProperties = computed(() =>
        toValue(variations)?.filter(variation => variation.getValidProperties().length === 0) ?? []
    )
    const _finalVariations: Ref<InstanceType<typeof ProductVariationModel>[]> = ref([])
    /**
     * The variations that exactly match the currently selected attributes.
     * They don't have any other attributes than the ones that are selected.
     *
     * In case that no variation can be determined, this array will be empty.
     *
     * It can happen that, for example, there are multiple variations in this array, so it cannot be used to determine
     * the selected variation on its own and a conditional selector should be rendered to let the user
     * pick the final variation. To determine whether to show the final variation selector,
     * use the `showFinalVariationSelector` computed property. (DO NOT use the length of this array to
     * determine it)
     *
     * @see showFinalVariationSelector
     */
    const finalVariations = computed(() => {
        return [
            ..._finalVariations.value,
            ...variationsWithoutProperties.value,
        ]
    })
    /**
     * Whether to show the final variation selector in the case when it cannot be determined
     * which variation is selected from the `finalVariations` array.
     */
    const showFinalVariationSelector = computed<boolean>(() => finalVariations.value.length > 1 || !!variationsWithoutProperties.value.length)

    // ----------------------------------------

    if (_options.storeSelectedVariationInQuery) {
        // select variation from query parameter (if applicable)
        const unwatch = watch(() => toValue(variations), (val) => {
            if (!val?.length) return
            setTimeout(() => unwatch(), 0)

            if (variationQuery.value) {
                const _selectedVariation = getProductVariations()?.find(v => v.id === variationQuery.value) ?? null

                if (_selectedVariation) {
                    const variationProperties = _selectedVariation.getValidProperties()

                    for (const property of allPossibleProductVariationProperties.value) {
                        const variationPropertyPairing = variationProperties.find(p => p.product_property_id === property.id)
                        // skip properties that are not in the selected variation
                        if (!variationPropertyPairing) continue

                        const selectedAttribute = property.getAttributes().find(
                            attr => attr.id === variationPropertyPairing.product_property_attribute_id
                        )

                        if (!selectedAttribute) continue

                        selectedAttributes.value[property.id as keyof typeof selectedAttributes.value] = selectedAttribute
                        handlePropertyChange({
                            property: property,
                            value: selectedAttribute,
                        }, false)
                    }
                }
            }
        }, { immediate: true })
    }

    // ----------------------------------------

    function getProductVariations(): InstanceType<typeof ProductVariationModel>[] | undefined {
        if (_options.allowNotPurchasable) return toValue(variations)
        return toValue(variations)?.filter(v => v.canBePurchased())
    }

    /**
     * Get all the available variations for the given product variation property.
     * This function respects the current combination of selected properties & their attributes and
     * returns only the variations that are still available for the given property.
     *
     * It can be used for all properties (including the ones already present in the combination stack)
     * @param property the product variation property for which to get the available variations
     */
    function getAvailableVariations(property: InstanceType<typeof ProductVariationPropertyModel>): InstanceType<typeof ProductVariationModel>[] {
        // COMBINATION strategy
        if (_options.strategy === 'combination') {
            return getProductVariations()?.filter(variation => variation.hasAttributes(Object.values(selectedAttributes.value))) ?? []
        }
        // -----------------------------------------------------------------------------------------------------------------
        // PATH strategy
        if (!combinationStack) {
            if (import.meta.dev) throw new Error('[useProductVariations]: The combination stack is not initialized. This should not happen with \'path\' strategy.')
            return []
        }

        const indexOfCurrentProperty = combinationStack?.value.findIndex(item => item.property.id === property.id)

        const variationsForProperty = indexOfCurrentProperty !== 0
            ? combinationStack?.value[indexOfCurrentProperty - 1]?.variations
            : getProductVariations() // TODO: add support for async fetching of productVariations with filters

        return (
            variationsForProperty ??
            combinationStack?.value.slice(-1)[0]?.variations ??
            getProductVariations() ??
            []
        ) as InstanceType<typeof ProductVariationModel>[]
    }

    /**
     * A function to handle the change of a product variation property.
     * In case the property changes its value, it should be provided. In case the property is unselected,
     * a value of `null` should be provided to the data.
     * @param data the event payload data
     * @param shouldUpdateQuery whether to update the query parameter with the selected variation or not (default: true)
     */
    function handlePropertyChange(data: VariationSelectorPropertyChangeData, shouldUpdateQuery = true) {
        // COMBINATION strategy
        if (_options.strategy === 'combination') {
            _finalVariations.value = getAvailableVariations(data.property)?.filter(variation =>
                // the number of properties in the variation === the number of selected attributes
                variation.getValidProperties().length === Object.values(selectedAttributes.value).filter(Boolean).length
            ) ?? []

            const isFinalVariationDistinguishable = _finalVariations.value.length === 1
            const finalVariation = isFinalVariationDistinguishable
                ? _finalVariations.value[0]!
                : null
            selectedVariation.value = finalVariation

            if (shouldUpdateQuery) updateQueryParam(finalVariation)
            return
        }

        // -----------------------------------------------------------------------------------------------------------------
        // PATH strategy
        if (!combinationStack) {
            if (import.meta.dev) throw new Error('[useProductVariations]: The combination stack is not initialized. This should not happen with \'path\' strategy.')
            return
        }

        unselectPropertiesAfter(data.property)

        // get the available variations for the current property
        let newAvailableVariations = getAvailableVariations(data.property) ?? []
        // filter out only the properties that have the selected value (if any, skip if the value was removed & is null)
        if (data.value) {
            newAvailableVariations = newAvailableVariations.filter(variation => variation.hasAttribute(data.value))

            combinationStack.value.push({
                property: data.property,
                attribute: data.value,
                variations: newAvailableVariations,
            })
        }

        // set the selected variation when there is only one available & reset it when it is no longer certain which variation is selected
        _finalVariations.value = newAvailableVariations.filter(variation => variation.getValidProperties().length === combinationStack.value.length)

        const isFinalVariationDistinguishable = _finalVariations.value.length === 1
        const finalVariation = isFinalVariationDistinguishable
            ? _finalVariations.value[0]!
            : null
        selectedVariation.value = finalVariation

        if (shouldUpdateQuery) updateQueryParam(finalVariation)
    }

    function unselectPropertiesAfter(property: InstanceType<typeof ProductVariationPropertyModel>) {
        if (!combinationStack) {
            if (import.meta.dev) throw new Error('[useProductVariations]: The combination stack is not initialized. This should not happen with \'path\' strategy.')
            return
        }

        const indexOfCurrentProperty = combinationStack.value.findIndex(item => item.property.id === property.id)

        // if the property is not in the list, return
        if (indexOfCurrentProperty === -1) return

        const attributesAfter = combinationStack.value.slice(indexOfCurrentProperty + 1).map(item => item.attribute)

        // remove the property and all following properties
        combinationStack.value.splice(indexOfCurrentProperty, combinationStack.value.length - indexOfCurrentProperty)

        // remove selected attributes for properties that are no longer selected
        const propertyIdsToNullify = attributesAfter.map(attr => attr?.productPropertyId ?? null).filter(Boolean)
        for (const propertyId of propertyIdsToNullify) {
            selectedAttributes.value[propertyId as keyof typeof selectedAttributes.value] = null
        }
    }

    const returnValue = {
        variationProperties: productVariationPropertiesToRender,
        selectedVariation: selectedVariation,
        selectedAttributes: selectedAttributes,
        getAvailableVariations: getAvailableVariations,
        handlePropertyChange: handlePropertyChange,
        finalVariations: finalVariations,
        showFinalVariationSelector: showFinalVariationSelector,
    }

    provide<UseProductVariationSelectorReturnValue>(VariationSelectorProvideSymbol, returnValue)

    return returnValue
}

interface UseProductVariationQueryOptions {
    /**
     * Whether to enable the query parameter handling.
     * @default true
     */
    enabled: boolean
}

const PRODUCT_VARIATION_QUERY_PARAM_NAME = 'v'
function useProductVariationQuery(options?: Partial<UseProductVariationQueryOptions>) {
    const _options = defu(options, {
        enabled: true,
    } satisfies UseProductVariationQueryOptions)

    const router = useRouter()
    const route = useRoute()

    const variationQuery = computed({
        get() {
            const param = Array.isArray(route.query[PRODUCT_VARIATION_QUERY_PARAM_NAME]) ? route.query[PRODUCT_VARIATION_QUERY_PARAM_NAME][0] : route.query[PRODUCT_VARIATION_QUERY_PARAM_NAME]
            const parsed = param ? parseInt(param) : null
            if (parsed === null || isNaN(parsed)) return null
            return parsed
        },
        set(val: number | null) {
            router.replace({
                query: {
                    ...route.query,
                    [PRODUCT_VARIATION_QUERY_PARAM_NAME]: val ?? undefined,
                },
            })
        },
    })

    function updateQueryParam(variation: InstanceType<typeof ProductVariationModel> | null) {
        if (!options?.enabled) return
        variationQuery.value = variation?.id ?? null
    }

    return {
        variationQuery: variationQuery,
        updateQueryParam: updateQueryParam,
    }
}

export function getProductUrl(product: InstanceType<typeof ProductModel> | null | undefined, variation: InstanceType<typeof ProductVariationModel> | null = null): string | null {
    const productUrl = product?.getUrl()
    if (!productUrl) return null
    return withQuery(productUrl, {
        [PRODUCT_VARIATION_QUERY_PARAM_NAME]: variation?.id,
    })
}
